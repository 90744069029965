<!-- 首页 -->
<template>
  <div style="height:100%;display: flex;">

    <div style="width:200px;background:#00786B;flex-shrink:0" >
      <div style="display: flex;height: 50px;justify-content: center;align-items: center;">
        <img src="../images/qslogo.png" alt="" style="width:16px;height: 16px;">
        <div style="font-size:14px;color: #fff;font-weight:600 ;">实效青松后台管理系统</div>
      </div>
      <el-menu :unique-opened='true' :router='true' class="el-menu-vertical-demo" @open="handleOpen"
        @close="handleClose" text-color="#fff" active-text-color="#00786B" background-color="#00786B">
        <div v-for="(item, index) of list" >
          <el-menu-item :index="item.uri" v-if="item.uri != ''" style="display: flex;align-items: center;">
            <div>
              <!-- <i class="el-icon-location"></i> -->
              <span slot="title" style="font-weight:bold;">{{ item.name }}</span>
            </div>
          </el-menu-item>
          <el-submenu :index="`${item.permissionId.toString()}`" v-if="item.children && item.children.length >= 1"
            style="text-align:center;">
            <template slot="title">
              <div>
                <!-- <i class="el-icon-location"></i> -->
                <span style="font-weight:bold;">{{ item.name }}</span>
              </div>
            </template>
            <el-menu-item-group v-for="(item2, index2) of item.children"  >
                <el-menu-item :index="item2.uri">{{ item2.name }}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </div>

      </el-menu>
    </div>
    <div style="display:flex;height:100%;flex-direction: column;width: calc(100% - 200px);background: #EEF5F4;">
      <div style="background: #fff;">
        <div class="titlemain" >
          <div class="right">
            <div style="color:#888" v-if="brandName">{{ brandName }}</div>
            <div style="margin:0 20px;color:#888" v-if="brandName">|</div>
            <el-dropdown trigger="click" @command="handleCommand" style="cursor: pointer;">
              <div>
                <img src="../images/head.png" alt="" style="
                width: 36px;
                height: 36px;
                border-radius: 50%;
                margin-right: 13px;
              " />
                <div>{{ $store.state.userInfo }}</div>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="exit">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

          </div>
        </div>
        <!-- <div v-if="roleId == 4" style="text-align: center;font-weight: bold;line-height: 85px;position: relative;"> <img
            src="../images/newleft.png" alt="" @click="goback"
            style="position:absolute;left: 20px;width: 20px;height: 20px;top: 50%;transform: translate(0,-50%);"
            v-if="homePage == 'false'" />
          <div>实效青松</div>
        </div> -->
      </div>
      <div class="main">
        <!-- roleId == 4 ? 'main2' : -->
        <router-view style="
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow:auto;"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      homePage: '',
      brandName: '',
      ind: 0,
      list: [],
      status: 1,//1正常端，2导师端，3审核端
      menuList: []
    };
  },
  updated() {
    this.homePage = sessionStorage.getItem('homePage')
  },
  created() {
    this.ind = this.$store.state.ind
    this.roleId = this.$store.state.roleId
    this.list = this.$store.state.permissionsList
    let brandList = this.$store.state.brandList
    let res = brandList.find(item => {
      return item.id == this.$store.state.brandId
    })
    // this.brandName = res.name
    // this.menuList=this.list[this.ind].permissionInfoList

  },
  methods: {
    goback() {
      this.$router.go(-1)
    },
    handleOpen(e) {
      console.log(e)
    },
    handleClose(e) {
      console.log(e)
    },
    clicktitle(index, url) {
      console.log(index)
      this.$store.commit('setIndex', index)
      this.ind = this.$store.state.ind
      this.menuList = this.list[this.ind].permissionInfoList
      this.$router.push({ path: this.list[this.ind].permissionInfoList[0].uri })
    },
    handleCommand(command) {
      if (command == 'exit') {
        this.$store.commit('setToken', '')
        this.$store.commit('setPermissionsList', '')
        this.$store.commit('setIndex', 0)
        this.$store.commit('setUserId','')
        this.$message.success('退出成功')
        setTimeout(() => {
          window.location.href = '/login'
        }, 500);
      }
    }
  }
};
</script>

<style lang='less' scoped>
.main {
  // width: 1200px;
  // margin: 0px auto;
  margin: 0px 10px;
  overflow: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  // width: 95% // background: white;
  // width: 95% 
    // min-height: 800px;
}

/deep/.el-submenu__title {
  text-align: left !important;
}

.main2 {
  // width: 1200px;
  // margin: 0px auto;
  // margin: 0px 50px;
  height: 100%;
  overflow: auto;
  width: 100% // background: white;
    // min-height: 800px;
}

.title {
  display: flex;
  margin-left: 72px;
  font-size: 15px;

  div {
    margin: 20px 15px;
  }
}

.title:hover {
  cursor: pointer;
}

.titlemain {
  display: flex;
  align-items: center;
  background: #fff;
  color: white;
  overflow: hidden;
  // height: 68px;
  height: 48px;
  // padding: 0 18%;
  // width: 1200px;

  justify-content:flex-end;
  padding:0 40px;
}

.titlemain>div:first-child {
  display: flex;
  align-items: center;
}

.right {
  display: flex;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    font-weight: bold;
  }
}

.right>img {
  margin-right: 20px;
}

.active {
  color: #fff
}

.uncheck {
  color: rgba(255, 255, 255, 0.6);
}

.is-active>div {
  background: #fff !important;
  border-radius: 50px 0 0 50px;
  width: 90%;
  height: 25px;
  line-height: 25px;
}
/deep/ul>li.el-menu-item.is-active{
  background: #fff !important;
}
// .is-active{
//    background: #fff !important;
//   border-radius: 50px 0 0 50px;
//   width: 90%;
// }
/deep/.el-menu-item,
/deep/.el-submenu__title {
  text-align: center
}

/deep/.el-menu-item.is-active {
  background: #F5F5F6
}

/deep/ .el-menu-item.is-active {
  //  background:rgba(100, 167, 116, 0.09)
  background: #F1F8F3;
}

/deep/.el-menu-item-group__title {
  padding: 0;
}

/deep/.el-menu-item,
.el-submenu__title {
  height: 50px;
  line-height: 50px;
}
</style>
